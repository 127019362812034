export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/phokitchen-logo3.png',
    banner_img_root : '/img/phokitchen-new-banner.jpg',
    resid : '',
    key_value : 'phokitchendm',
    secret_value : 'phokitchendm',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.3892952125134!2d-105.2578033851972!3d39.9997510794158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bedb746483599%3A0x25f3f8a52e61243d!2sPho%20Kitchen%20Bar%20%26%20Grill!5e0!3m2!1sen!2sin!4v1583222903059!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Pho Kitchen Bar & Grill"
};